import { useEffect, useState } from 'react';
import logo from './logo.svg';
// import './App.css';

function App() {
  const specificDate = new Date('2024-12-06 00:00:00');
  const [likeCount, setLikeCount] = useState(6900)
  const defaultLikeCount = 69
  const [dislikeCount, setDislikeCount] = useState(690)
  const defaultDislikeCount = 42
  const [viewCount, setViewCount] = useState(886000)
  const defaultViewCount = 886
  const [commentCount, setCommentCount] = useState(4200)
  const [shareCount, setShareCount] = useState(453)
  const defaultShareCount = 453
  const displayRealTimeCount = (startDate) => {
    const startDateInSeconds = Math.floor(startDate.getTime() / 1000);
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const timeElapsedInSeconds = currentTimeInSeconds - startDateInSeconds;
    const simulatedViewCount = timeElapsedInSeconds/10;
    const simulatedLikeCount = timeElapsedInSeconds/100;
    const simulatedDislikeCount = timeElapsedInSeconds/1000;
    const simulatedShareCount = timeElapsedInSeconds/2000;

    const formattedViewCount = simulatedViewCount.toFixed(2);
    const formattedLikeCount = simulatedLikeCount.toFixed(2);
    const formattedDislikeCount = simulatedDislikeCount.toFixed(2);
    const formattedShareCount = simulatedShareCount.toFixed(2);

    console.log(`Timestamp counting from ${startDate}: ${timeElapsedInSeconds}`);
    setViewCount(defaultViewCount+Number(formattedViewCount.split(".")[0]))
    setLikeCount(defaultLikeCount+Number(formattedLikeCount.split(".")[0]))
    setDislikeCount(defaultDislikeCount+Number(formattedDislikeCount.split(".")[0]))
    setShareCount(defaultShareCount+Number(formattedShareCount.split(".")[0]))
    setTimeout(()=>{
      displayRealTimeCount(specificDate)
    }, 1000)
  }
  
  // Update the count every second
  // setInterval(displayRealTimeCount(specificDate), 1000);
  useEffect(()=>{
    displayRealTimeCount(specificDate)
  }, [viewCount])
  return (
    <div className="App row center">
      <div className='row'>
        <div className='box row'>
            <div className='col-0'>
              <div className='row center pwnNotification'></div>
              <div className='row center pwnNotification2'></div>
              <div className='row center pwnNotification3'></div>
              <div className='row center pwnNotification4'></div>
              <div className='row center'><b className='secret'>I know where you live</b></div>
              <div className='row center space-around more'>
                <b>THÔNG TIN LIÊN HỆ</b>
              </div>
              <div className='row space-around more'>
                <div className='row space-around'>Tên:</div> <p className='blurred'>Nguyên Phấn Đông</p>
              </div>
              <div className='row space-around more'>
                <div className='row space-around'>Ngày tháng năm sinh:</div> <p className='blurred'>06/09/1989</p>
              </div>
              <div className='row space-around more'>
              <div className='row space-around'>SĐT:</div> +84 886 453 841
              </div>
              <div className='row space-around more'>
                <div className='row space-around'>Địa chỉ:</div> <p className='blurred mini-box'>Căn hộ số 4, Tà Oa, 420/69 Chung cư Yên Nhan, Phong Xích Lan, Phường Ai Biến, Niêu Kình, Cờ Thân</p>
              </div>
              <div className='row interactions'>
                <div className='space-around'><i className='fas fa-thumbs-up'></i></div>
                {/* <div className='space-around'>6.9K</div> */}
                <div className='space-around'>{likeCount}</div>
                <div className='space-around'><i className='fas fa-thumbs-down'></i></div>
                {/* <div className='space-around'>4.2K</div> */}
                <div className='space-around'>{dislikeCount}</div>
                <div className='space-around'><i className='fas fa-eye'></i></div>
                {/* <div className='space-around'>886</div> */}
                <div className='space-around'>{viewCount}</div>
                <div className='space-around'><i className='fas fa-share'></i></div>
                {/* <div className='space-around'>453</div> */}
                <div className='space-around'>{shareCount}</div>
              </div>
              
            </div>
            
        </div>
        
        
      </div>
     
    </div>
  );
}

export default App;
